
import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class PdfContent extends Vue {
  @Prop() content!:string
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.$emit("domRendered");
      }, 1000);
    });
  }
};
