
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import {
  mdiAlignHorizontalCenter, mdiAlignHorizontalLeft, mdiAlignHorizontalRight,
  mdiAlignVerticalBottom, mdiAlignVerticalCenter, mdiAlignVerticalTop,
  mdiArrowAll, mdiCog, mdiPen, mdiPlus, mdiTrashCan, mdiUpload
} from "@mdi/js";
import draggable from "vuedraggable";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import JsonString from "@/components/common/inline-editor/jsonstring.vue";
import Ace from "@/components/helpers/ace.vue";
import BlockConfigurator from "@/pages/manage/events/components/booklet-configuration-editor/block-configurator.vue";

@Component({
  components: {BlockConfigurator, Ace, JsonString, DataEditor, draggable}
})
export default class BookletEditor extends Vue {
  @Prop() bookletTemplateDataSource!:ObjectDataSource
  @Prop() dataSource!:ObjectDataSource

  selectedTemplatePageId:number|null = null



  get bookletTemplatePage(){
    return this.bookletTemplateDataSource.model.pages
      ?.find((p:any) => p.id == this.selectedTemplatePageId)
      ?.blocks.sort((p: any, n: any) => p.priority - n.priority);
  }



  icons: any = {
    arrowAll: mdiArrowAll,
    plus: mdiPlus,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    alignLeft: mdiAlignHorizontalLeft,
    alignCenter: mdiAlignHorizontalCenter,
    alignRight: mdiAlignHorizontalRight,
    alignTop: mdiAlignVerticalTop,
    alignMiddle: mdiAlignVerticalCenter,
    alignBottom: mdiAlignVerticalBottom,
  };
  $message: any;


  getPageSate(pageId:number) {
    return this.selectedTemplatePageId === pageId
      ? { alertColor: "success", textColor: "white--text", iconsColor: "white" }
      : { alertColor: "default", textColor: "", iconsColor: "" };
  }


  mounted(){
    this.bookletTemplateDataSource.model.pages=  this.bookletTemplateDataSource.model.pages.sort(
      (p: any, n: any) => p.priority - n.priority
    )
    this.selectedTemplatePageId = this.bookletTemplateDataSource.model?.pages?.[0]?.id;
  }
}
